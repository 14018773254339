import { ExamAnswerArea, ExamPartStatus, ExamNetPartState } from '@/constants'

type PartValidation = {
  status: 'valid' | 'invalid' | 'info'
  message?: string
  examNetStatus?: ExamNetPartState
}

export type ExamPartForValidation = Pick<ExamPart, 'tools' | 'config'>

export function validatePart(
  parts: ExamPartForValidation[],
  partIndex: number
): PartValidation {
  if (partIndex === 0) {
    // first part cannot get any validation error
    return {
      status: 'valid',
      message: '',
      examNetStatus: ExamNetPartState.WRITABLE,
    }
  }

  // after a closed part can only a closed part follow. Show error for all other parts after a closed part
  for (let prevIndex = partIndex - 1; prevIndex >= 0; prevIndex--) {
    if (parts[prevIndex].tools?.state === ExamPartStatus.CLOSED) {
      if (parts[partIndex].tools?.state !== ExamPartStatus.CLOSED) {
        return {
          status: 'invalid',
          message: 'exam.partStatus.invalidClosed',
        }
      }
    }
  }

  if (
    parts[partIndex - 1].config.answerArea === ExamAnswerArea.SEPARATE &&
    parts[partIndex].config.answerArea === ExamAnswerArea.INLINE
  ) {
    // previous part had answers on separate page
    if (parts[partIndex].tools?.state === ExamPartStatus.OPEN) {
      return {
        status: 'info', // TODO: Confirm with Sara
        message: 'exam.partStatus.infoSeparatePage',
        examNetStatus: ExamNetPartState.TOOLS_ON_TEACHER_APPROVE,
      }
    }
  }

  if (parts[partIndex].tools?.state === ExamPartStatus.OPEN) {
    const change = previousPartToolDifference(
      parts[partIndex - 1].tools,
      parts[partIndex].tools
    )
    if (change.toolsRemoved) {
      return {
        status: 'invalid',
        message: 'exam.partStatus.invalidOpen',
      }
    }
    if (change.toolsAdded) {
      return {
        status: 'info',
        message: 'exam.partStatus.infoOpen',
        examNetStatus: ExamNetPartState.TOOLS_ON_STUDENT_SUBMIT,
      }
    }
  }

  return {
    status: 'valid',
    message: '',
    examNetStatus: gaussStateToExamNetState(parts[partIndex].tools?.state),
  }
}
function gaussStateToExamNetState(status: ExamPartStatus): ExamNetPartState {
  switch (status) {
    case ExamPartStatus.OPEN:
      return ExamNetPartState.WRITABLE
    case ExamPartStatus.HIDDEN_UNTIL_STUDENT_SUBMIT:
      return ExamNetPartState.HIDDEN_UNTIL_STUDENT_SUBMIT
    case ExamPartStatus.HIDDEN_UNTIL_TEACHER_APPROVE:
      return ExamNetPartState.HIDDEN_UNTIL_TEACHER_APPROVE
    case ExamPartStatus.CLOSED:
      return ExamNetPartState.PAUSED
    default:
      return ExamNetPartState.PAUSED // or other default value as needed
  }
}
function previousPartToolDifference(
  prevTools?: ExamPartTools,
  currentTools?: ExamPartTools
) {
  const scienceTools = ['calculator', 'desmos', 'geogebraType'] as (
    | 'calculator'
    | 'desmos'
    | 'geogebraType'
  )[]

  let toolsAdded = false
  let toolsRemoved = false

  scienceTools.forEach((tool) => {
    const was = prevTools?.science[tool] ?? false
    const now = currentTools?.science[tool] ?? false

    if (tool === 'geogebraType') {
      const changes = handleGeogebraChange(String(was), String(now))
      toolsAdded = toolsAdded || changes.added
      toolsRemoved = toolsRemoved || changes.removed
    } else {
      toolsRemoved = toolsRemoved || (Boolean(was) && !now)
      toolsAdded = toolsAdded || (!was && Boolean(now))
    }
  })

  return { toolsAdded, toolsRemoved }
}

type ToolChanges = { added: boolean; removed: boolean }

function handleGeogebraChange(was: string, now: string): ToolChanges {
  return {
    added:
      (was === 'NONE' && now !== 'NONE') || (was !== now && now !== 'NONE'), // Geogebra is added or changed to another version
    removed:
      (was !== 'NONE' && now === 'NONE') || (was !== now && was !== 'NONE'), // Geogebra is removed or changed to another version
  }
}
