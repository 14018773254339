import { defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'
import {
  BinaryOption,
  PageSize,
  FIRST_PAGE_INDEX,
  SearchVisibility,
  SORT_BY_RELEVANCE_KEY,
} from '@/constants'

type FacetItem = {
  count: number
  value: string
}

type Facets = {
  literatures: FacetItem[]
  types: FacetItem[]
  attachments?: FacetItem[]
}

type QueryParams = {
  query?: string
  sortBy: string
  autocorrect?: boolean
  calculator?: boolean
  abilities?: string[]
  pointTypes?: string[]
  literature?: string
  types?: string[]
  visibility?: string
  studentAccess?: boolean
  forceSearch: number
  page: number
  pageSize: number
}

type QuestionFilters = {
  query: string
  sortBy: string
  autocorrect: string
  calculator: string
  abilities: string[]
  pointTypes: string[]
  literature: string
  type: string
  visibility: string
  studentAccessBlocked: string
  forceSearch: number
}

type Store = {
  seriesBookId: number | null
  contentIds: Record<string, boolean> | null
  contentLabels: string[]
  params: QuestionFilters
  facets: Facets | null
  page: number
  pageSize: number
}

const IGNORED_PARAMS = ['query', 'sortBy', 'forceSearch', 'page', 'pageSize']

const BLANK_PARAMS = {
  sortBy: SORT_BY_RELEVANCE_KEY,
  query: '',
  autocorrect: '',
  calculator: '',
  abilities: [],
  pointTypes: [],
  literature: '',
  type: '',
  visibility: SearchVisibility.ALL,
  studentAccessBlocked: '',
  forceSearch: 0,
}

const initialStore = (): Store => ({
  seriesBookId: null, // used for book in bookseries
  contentIds: null,
  contentLabels: [],
  params: JSON.parse(JSON.stringify(BLANK_PARAMS)),
  facets: null,
  page: FIRST_PAGE_INDEX,
  pageSize: PageSize.NORMAL,
})

export const useSearchStore = defineStore('search', {
  state: () => ({
    search: useSessionStorage('search', initialStore()),
  }),
  getters: {
    queryParams(): QueryParams {
      return {
        sortBy: this.search.params.sortBy,
        ...(this.search.params.query && {
          query: this.search.params.query,
        }),
        ...(this.search.params.autocorrect && {
          autocorrect: this.search.params.autocorrect === BinaryOption.YES,
        }),
        ...(this.search.params.calculator && {
          calculator: this.search.params.calculator === BinaryOption.YES,
        }),
        ...(this.search.params.abilities.length > 0 && {
          abilities: this.search.params.abilities,
        }),
        ...(this.search.params.pointTypes.length > 0 && {
          pointTypes: this.search.params.pointTypes,
        }),
        ...(this.search.params.type && {
          type: this.search.params.type,
        }),
        ...(this.search.params.literature && {
          literature: this.search.params.literature,
        }),
        ...(this.search.params.visibility && {
          visibility: this.search.params.visibility,
        }),
        ...(this.search.params.studentAccessBlocked && {
          studentAccess:
            this.search.params.studentAccessBlocked === BinaryOption.NO,
        }),
        forceSearch: this.search.params.forceSearch,
        page: this.search.page,
        pageSize: this.search.pageSize,
      }
    },
    numberOfQueryParams(): number {
      return (
        Object.keys(this.queryParams).length - this.numberOfIgnoredQueryParams
      )
    },
    numberOfIgnoredQueryParams(): number {
      let numberOfIgnoredParams = 0
      Object.keys(this.queryParams).forEach((param: string) => {
        if (
          IGNORED_PARAMS.includes(param) ||
          (param === 'visibility' &&
            this.queryParams.visibility === SearchVisibility.ALL)
        ) {
          numberOfIgnoredParams++
        }
      })
      return numberOfIgnoredParams
    },
    nodeList(): object[] {
      const nodeList: object[] = []
      let hasRepetition = false
      Object.keys(this.search.contentIds || {}).forEach((selectedKey) => {
        const [chapterKey, subchapterKey] = selectedKey.split('_')
        if (subchapterKey) {
          if (chapterKey === 'repetition') {
            hasRepetition = true
            nodeList.push({ id: Number(subchapterKey), type: 'repetition' })
          } else {
            nodeList.push({ id: Number(subchapterKey), type: 'subchapter' })
          }
        } else {
          if (chapterKey === 'repetition') {
            nodeList.push({ id: 0, type: 'allRepetition' })
          } else {
            nodeList.push({ id: Number(chapterKey), type: 'chapter' })
          }
        }
      })
      if (hasRepetition) {
        // Repetition can not be mixed with other chapters or subchapters
        return nodeList.filter((node: any) => node.type === 'repetition')
      }
      return nodeList
    },
    expandedChapters(): Record<number, boolean> {
      return Object.keys(this.search.contentIds || {}).reduce(
        (acc, key) => {
          const chapterId = key.split('_')[0]
          acc[Number(chapterId)] = true
          return acc
        },
        {} as Record<number, boolean>
      )
    },
  },
  actions: {
    forceSearch() {
      this.search.params.forceSearch++
    },
    setContent(contentIds: Record<string, boolean>, labels: string[]) {
      this.search.contentIds = contentIds
      this.search.contentLabels = labels
    },
    setPageIndex(index: number) {
      this.search.page = index
    },
    setPageSize(size: number) {
      this.search.pageSize = size
    },
    setFacets(facets: any) {
      this.search.facets = facets
    },
    setSeriesBookId(id: number) {
      this.search.seriesBookId = id
    },
    clearContentSelection() {
      this.search.contentIds = null
      this.search.contentLabels = []
    },
    handleInjectedTerm(query: string) {
      const forceSearch = this.search.params.forceSearch
      // reset some state only
      this.search.params = JSON.parse(JSON.stringify(BLANK_PARAMS))
      this.search.facets = null
      this.search.page = FIRST_PAGE_INDEX
      this.search.pageSize = PageSize.NORMAL
      // end reset
      this.search.params.forceSearch = forceSearch + 1
      this.search.params.query = query
    },
    reset() {
      this.search = initialStore()
    },
  },
})
