import {
  ExamAnswerArea,
  ExamPartStatus,
  ExamPartStatusAfterSubmission,
  GeogebraType,
  SpellCheckLanguage,
  TranslationType,
} from '@/constants'

function getExamPartSettingsHeading(
  km: boolean,
  tt: (key: string, params?: any) => string,
  numberOfParts: number,
  partIndex: number,
  partConfig: ExamPartConfig,
  courseHasCalculator: boolean
) {
  const hasSomeAnswerAreas = partConfig.answerArea === ExamAnswerArea.INLINE
  if (numberOfParts === 1 && hasSomeAnswerAreas) {
    return ''
  }
  const calculator = partConfig.calculatorAllowed

  let title = tt('exam.part.title', {
    index: String.fromCharCode(65 + partIndex),
  })
  title += ': '
  if (courseHasCalculator) {
    const text = calculator
      ? tt('exam.part.heading.digitalToolsAllowed')
      : tt('exam.part.heading.digitalToolsNotAllowed')
    title += text
    title += ' '
  }
  const answerInfo = hasSomeAnswerAreas
    ? tt('exam.part.heading.answerInline')
    : tt('exam.part.heading.answerSeparate')
  title += answerInfo
  if (km) {
    const style =
      'margin-left:-0px; padding: 3px 5px 2px 5px; border:solid 1px black;'
    return `<div style="${style}">${title}</div>
    <div class="km-paragraph">&nbsp;</div>`
  } else {
    return title
  }
}

function getExamPartHeadingFromConfig(
  km: boolean,
  numberOfParts: number,
  partIndex: number,
  config: ExamPartConfig,
  courseHasCalculator: boolean,
  tt: any
) {
  const heading = config.heading
  return heading !== undefined // empty string is a valid heading
    ? heading
    : getExamPartSettingsHeading(
        km || false,
        tt,
        numberOfParts,
        partIndex,
        config,
        courseHasCalculator
      )
}

function getDefaultExamPartConfig(calculator: boolean): ExamPartConfig {
  return {
    calculatorAllowed: calculator,
    answerArea: ExamAnswerArea.INLINE,
  }
}

function getDefaultExamPartTools(calculator: boolean): ExamPartTools {
  return {
    state: ExamPartStatus.OPEN,
    examNetState: ExamPartStatus.OPEN,
    partsAfterSubmission: ExamPartStatusAfterSubmission.HIDDEN,
    drawingArea: false,
    camera: false,
    audioRecording: false,
    programming: false,
    language: {
      spellCheck: SpellCheckLanguage.NONE,
      textToSpeech: false,
      translate: TranslationType.NONE,
      dictionaries: [],
    },
    science: {
      geogebraType: calculator ? GeogebraType.SUITE : GeogebraType.NONE,
      desmos: calculator,
      calculator,
      formula: false,
    },
  }
}

export {
  getExamPartSettingsHeading,
  getDefaultExamPartConfig,
  getDefaultExamPartTools,
  getExamPartHeadingFromConfig,
}
