import {
  http,
  httpStudent,
  throwOnAxiosError,
  wrapAxiosError,
  isAxiosError,
} from './http'
import { UserFacingError } from '@/utils/UserFacingError'
import { PostRedirect } from '@/utils/PostRedirect'
import { PageCodeStatus, ExamStatus } from '@/constants'
import { ExamConflictException } from './exceptions/ExamConflictException'

type ExamPartsForSaving = Omit<ExamPart, 'questions'> & {
  config: ExamPartConfig
  tools?: ExamPartTools
  questionVersionIds: number[]
  questionsEdited: Record<number, boolean>
}

export type ExamForSaving = Omit<
  Exam,
  | 'parts'
  | 'language'
  | 'course'
  | 'id'
  | 'subject'
  | 'createdAt'
  | 'updatedAt'
  | 'exportedAt'
  | 'examKey'
  | 'externalExamId'
  | 'examResources'
  | 'blockStudentAccess'
> & {
  parts?: ExamPartsForSaving[]
}
type ExamExportStatus = Pick<
  Exam,
  'id' | 'externalExamId' | 'status' | 'exportedAt' | 'examKey'
>

export async function examGetById(
  id: number,
  anonymous = false
): Promise<Exam> {
  try {
    let res = null
    if (anonymous) {
      res = await http.get(`/v1/exam/${id}/anonymous`)
      return res.data
    } else {
      res = await http.get(`/v1/me/exam/${id}`)
    }
    return res.data
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 404) {
      throw new UserFacingError(
        err,
        PageCodeStatus.NOT_FOUND,
        false,
        'generic.error.examNotFound'
      )
    } else {
      throw wrapAxiosError(err, 'Failed to retrieve exam')
    }
  }
}
export async function examGetByUUId(code: any) {
  try {
    const response = await http.get(`/v1/me/exams/${code}/uuid`)
    return response.data
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 404) {
      throw new UserFacingError(
        err,
        PageCodeStatus.NOT_FOUND,
        false,
        'generic.error.examNotFound'
      )
    } else {
      throw wrapAxiosError(err, 'Failed to retrieve exam')
    }
  }
}
export async function examGetByIdStudent(
  examId: number,
  _anonymous = false
): Promise<Exam> {
  const res = await httpStudent.get(`/v1/exams/${examId}`)
  return res.data
}

export async function examCreate(exam: ExamForSaving) {
  try {
    const res = await http.post(`/v1/me/exam/`, exam)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to create exam')
  }
}

export async function examSave(id: number, exam: ExamForSaving) {
  try {
    const res = await http.put(`/v1/me/exam/${id}`, exam)
    return res.data
  } catch (err) {
    if (isAxiosError(err) && err?.response?.status === 409) {
      throw new ExamConflictException()
    }

    throwOnAxiosError(err, 'Failed to save exam')
  }
}

export async function examSetStatus(
  id: number,
  status: ExamStatus,
  forceExport = false
): Promise<ExamExportStatus> {
  try {
    const res = await http.put(`/v1/me/exam/${id}/status`, {
      status,
      forceExport,
    })
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to set exam status')
  }
}

export async function examDelete(id: number) {
  try {
    await http.delete(`/v1/me/exam/${id}`)
  } catch (err) {
    throwOnAxiosError(err, 'Failed to delete exam')
  }
}

export async function examStatusGet(id: number) {
  try {
    const res = await http.get(`/v1/me/exam/${id}/status`)
    return res.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to get exam status')
  }
}

async function mapExamPreviewById(examId: number) {
  try {
    const response = await http.post(`/v1/me/exam/${examId}/export/map`)
    return response.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to export exam')
  }
}

export async function examPreviewById(id: number) {
  const mappedExam = await mapExamPreviewById(id)
  PostRedirect({
    url: `${import.meta.env.VITE_EXAM_BASE_URL}/gauss/v3/preview`,
    newTab: true,
    data: {
      examId: String(id),
      exam: JSON.stringify(mappedExam),
    },
  })
}

export async function examShare(examId: number, email?: string) {
  try {
    const response = await http.post(
      `/v1/me/exams/${examId}/share`,
      email && { email }
    )
    return response.data
  } catch (err) {
    throwOnAxiosError(err, 'Failed to share exam')
  }
}

export async function examUploadFileResource(
  examId: number,
  data: FormData
): Promise<ExamResource> {
  const response = await http.post(
    `/v1/me/exam/${examId}/resources/upload`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

  return response.data
}

export async function examResourceAdd(
  examId: number,
  data: ExamResource[]
): Promise<ExamResource[]> {
  const response = await http.post(`/v1/me/exam/${examId}/resources`, {
    links: data,
  })

  return response.data
}

export async function examResourceUpdate(examId: number, data: ExamResource[]) {
  let payload = {}
  if (data[0].type === 'link') {
    payload = { links: data }
  } else {
    payload = { ...data[0] }
  }

  const response = await http.put(
    `/v1/me/exam/${examId}/resources/${data[0].id}`,
    payload
  )

  return response.data
}

export async function examResourceDelete(examId: number, resourceId: number) {
  const response = await http.delete(
    `/v1/me/exam/${examId}/resources/${resourceId}`
  )
  return response
}
